<template>
  <div style="background-color: #f7f7f7">
    <div>
      <div class="hospitalBox">
        <img src="../../assets/touxiang.png"
          style="width: 50px; margin: 1%" />
        <div class="title"
          style="font-family: PingFang SC; font-weight: 500; margin: 1%">
          <div>姓名：{{name}}</div>
          <div>年龄：{{age}}岁</div>
        </div>
      </div>
      <div style="padding: 20px 0 10px 20px">
        <div class="biankuang"></div>
        <h3 style="
            display: initial;
            padding-left: 10px;
            font-family: PingFang SC;
            font-weight: 500;
          ">
          身体指标
        </h3>
      </div>
      <!-- <div class="zhibiao">
        <img src="../../assets/jg.png"
          style="width: 40px; margin: 10px" />
        <div class="zhibiaoXy">
          <div>血压</div>
        </div>
        <div class="zhibiaoNr">
          <van-cell-group>
            <van-field v-model="dataform.xueya"
              placeholder="请输入血压"
              style="padding-left:0;" />
          </van-cell-group>
        </div>
      </div>
      <div class="zhibiao">
        <img src="../../assets/jg.png"
          style="width: 40px; margin: 10px" />
        <div class="zhibiaoXy">
          <div>心率</div>
        </div>
        <div class="zhibiaoNr">
          <van-cell-group>
            <van-field v-model="dataform.xinlv"
              placeholder="请输入心率"
              style="padding-left:0;" />
          </van-cell-group>
        </div>
      </div>
      <div class="zhibiao">
        <img src="../../assets/jg.png"
          style="width: 40px; margin: 10px" />
        <div class="zhibiaoXy">
          <div>血氧</div>
        </div>
        <div class="zhibiaoNr">
          <van-cell-group>
            <van-field v-model="dataform.xueyang"
              placeholder="请输入血氧"
              style="padding-left:0;" />
          </van-cell-group>
        </div>
      </div>
      <div class="zhibiao">
        <img src="../../assets/jg.png"
          style="width: 40px; margin: 10px" />
        <div class="zhibiaoXy">
          <div>睡眠</div>
        </div>
        <div class="zhibiaoNr">
          <van-cell-group>
            <van-field v-model="dataform.time"
              placeholder="请输入睡眠时间"
              style="padding-left:0;" />
          </van-cell-group>
        </div>
        <div style="width:90%;margin:10px auto;">
          <van-button style="width:100%;"
            round
            type="info"
            @click="SaveZh()">提交</van-button>
        </div>

      </div> -->
      <!-- 上传老人健康信息 -->
      <van-cell-group>
        <van-field v-model="Healthy.GroupName"
          @click="Groupshow = true"
          readonly="readonly"
          label="录入信息分类"
          placeholder="请选择录入信息分类" />
        <van-popup v-model="Groupshow"
          position="bottom"
          get-container="body">
          <van-picker show-toolbar
            :columns="groupList"
            @cancel="Groupshow = false"
            @confirm="onGroupshow">
          </van-picker>
        </van-popup>
        <van-field v-model="Healthy.HighPressure"
          v-if="Healthy.Group == 1"
          label="血压(高压)"
          placeholder="请输入测量血压" />
        <van-field v-model="Healthy.LowPressure"
          v-if="Healthy.Group == 1"
          label="血压(低压)"
          placeholder="请输入测量血压" />
        <van-field v-model="Healthy.HeartRate"
          v-if="Healthy.Group == 3"
          label="平均心率(分)"
          placeholder="请输入平均心率" />
        <van-field v-model="Healthy.BloodSugar"
          v-if="Healthy.Group == 4"
          label="血糖(mmol/l)"
          placeholder="请输入血糖值" />
        <van-field v-model="Healthy.Weight"
          v-if="Healthy.Group == 5"
          label="体重(KG)"
          placeholder="请输入体重" />
        <van-field v-model="Healthy.Temperature"
          v-if="Healthy.Group == 2"
          label="体温(℃)"
          placeholder="请输入血脂" />
        <van-field v-model="Healthy.Bloodfat"
          v-if="Healthy.Group == 6"
          label="血脂(mmol/L)"
          placeholder="请输入血脂" />
        <van-field v-model="Healthy.AGES"
          v-if="Healthy.Group == 7"
          label="糖化血红蛋白(mmol/L)"
          placeholder="请输入糖化血红蛋白" />
        <van-field v-model="Healthy.BloodOxygen"
          v-if="Healthy.Group == 8"
          label="血氧"
          placeholder="请输入血氧" />
        <van-field v-model="Healthy.Urinalysis"
          v-if="Healthy.Group == 9"
          label="尿酸"
          placeholder="请输入尿酸" />
      </van-cell-group>
      <div style="width:90%;margin:10px auto;">
        <van-button style="width:100%;"
          round
          type="info"
          @click="SaveHealthy(hId)">提交</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
import {
  WeSaveHealthy,
  WeGetElderlyBaseInfo
} from "@/api/Pension";
import config from "@/config";
import axios from "axios";
import { setOpenId, getOpenId } from "@/utils/auth";
export default {
  data () {
    return {
      dataform: {
        xueya: '',
        xinlv: '',
        xueyang: '',
        time: '',
      },
      Healthy: {
        //健康信息
        HId: 0, //老人id、
        OpenID: getOpenId(), //微信用户openid,
        // OpenID: 'o_4vZ5MGSqnC46Ma9fic3olT3JvM', //微信用户openid,
        HighPressure: undefined, //血压-高压,
        LowPressure: undefined, //血压-低压,
        HeartRate: undefined, //平均心率,
        BloodSugar: undefined, //血糖值,
        Weight: undefined, //体重kg,
        Temperature: undefined, //体温,
        Bloodfat: undefined, //血脂,
        AGES: undefined, //糖化血红蛋白,
        BloodOxygen: undefined, //血氧,
        Urinalysis: undefined, //尿酸,
        Group: "", //录入信息分类（1、血压、2-体温、3-心率、4-血糖）
      },
      groupList: [
        { ID: 1, text: "血压" },
        { ID: 2, text: "体温" },
        { ID: 3, text: "心率" },
        { ID: 4, text: "血糖" },
        { ID: 5, text: "体重" },
        { ID: 6, text: "血脂" },
        { ID: 7, text: "糖化血红蛋白" },
        { ID: 8, text: "血氧" },
        { ID: 9, text: "尿酸" },
      ],
      Groupshow: false, //信息分类
      hId: "",
      name: "",
      age: ""
    }
  },
  created () {
    this.laorenXx();
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    console.log(getOpenId())
  },
  methods: {
    laorenXx: function () {
      this.OpenID = getOpenId();
      WeGetElderlyBaseInfo({ openID: this.OpenID }).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          this.hId = res.data.data.HId;
          this.name = res.data.data.Name;
          this.iden = res.data.data.IdNumber;
          var birth = null;
          var myDate = new Date();
          var month = myDate.getMonth() + 1;
          var day = myDate.getDate();
          var age = 0;
          if (this.iden.length === 18) {
            age = myDate.getFullYear() - this.iden.substring(6, 10) - 1;
            birth =
              this.iden.substring(6, 10) +
              "-" +
              this.iden.substring(10, 12) +
              "-" +
              this.iden.substring(12, 14);
            if (
              this.iden.substring(10, 12) < month ||
              (this.iden.substring(10, 12) == month && this.iden.substring(12, 14) <= day)
            )
              age++;
          }
          if (this.iden.length === 15) {
            age = myDate.getFullYear() - this.iden.substring(6, 8) - 1901;
            birth =
              "19" +
              this.iden.substring(6, 8) +
              "-" +
              this.iden.substring(8, 10) +
              "-" +
              this.iden.substring(10, 12);
            if (
              this.iden.substring(8, 10) < month ||
              (this.iden.substring(8, 10) == month && this.iden.substring(10, 12) <= day)
            )
              age++;
          }
          this.age = age;
          console.log(this.age);
        } else {
          Toast.fail(res.data.msg);
        }
      })
    },
    SaveZh: function () {
      console.log(this.dataform);
    },
    //分类选择器
    onGroupshow (val) {
      this.Healthy = {
        HId: this.hId,
        OpenID: getOpenId(),
        HighPressure: undefined, //血压-高压,
        LowPressure: undefined, //血压-低压,
        HeartRate: undefined, //平均心率,
        BloodSugar: undefined, //血糖值,
        Weight: undefined, //体重kg,
        Temperature: undefined, //体温,
        Bloodfat: undefined, //血脂,
        AGES: undefined, //糖化血红蛋白,
        BloodOxygen: undefined, //血氧,
        Urinalysis: undefined, //尿酸,
      };
      this.Healthy.Group = val.ID;
      this.Healthy.GroupName = val.text;
      this.Groupshow = false;
    },
    // 录入健康信息
    SaveHealthy: function (hId) {
      console.log(hId);
      this.Healthy.HId = hId
      WeSaveHealthy(this.Healthy).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Toast.success("提交成功！");
        } else {
          Toast.fail("提交失败，" + res.data.msg);
        }
      });
    },
  }
}  
</script>

<style scoped>
.hospitalBox {
  position: relative;
  background: #fff;
  margin-top: 10px;
}
.title {
  height: 50px;
  line-height: 50px;
  display: inline-block;
  margin: 10px;
}
.title div {
  float: left;
  margin: 0 15px;
  font-size: 1.17em;
  font-weight: 540;
}
.zhibiao {
  position: relative;
  background: #fff;
}
.zhibiaoXy {
  height: 45px;
  line-height: 50px;
  display: inline-block;
  margin: 10px;
  width: 65%;
}
.zhibiaoXy div {
  float: left;
  font-size: 16px;
  font-weight: 540;
}
.zhibiaoNr {
  position: inherit;
  line-height: 30px;
  display: inline-block;
  margin-left: 70px;
  width: 65%;
  color: #999999;
  border-bottom: 1px solid #eeeeeeff;
}
.biankuang {
  border: 2px solid #ff9d30ff;
  background-color: #ff9d30ff;
  width: 0;
  height: 20px;
  float: left;
  border-radius: 2px;
}
.van-cell {
  font-size: 16px;
}
</style>